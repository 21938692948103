import axios from 'axios'
import * as types from './types'

declare const VWO: any

const triggerVWOTracking = (
  isSuccess: boolean,
  amount: number = 0,
  packageName: string = ''
): void => {
  if (isSuccess) {
    VWO.event('purchase', {
      revenue: amount,
      checkout: true,
      package: packageName,
    })
  } else {
    VWO.event('purchase', {
      revenue: 0,
      checkout: false,
      package: packageName,
    })
  }
}

export const getSession = () => {
  return axios.get<any>('/api/v2/session')
}

export const getUserById = (id: string) => {
  return axios.get<types.User>(`/api/v2/users/${id}`)
}

export const getOrder = (body: types.OrderPayload) => {
  return axios.get<types.OrderData>(
    `/api/v2/users/${body?.userId}/orders/${body?.orderId}`
  )
}

export const createAffirmCharge = (
  body: types.CreateAffirmChargePaylod,
  pt?: string
) => {
  return axios
    .post<types.OrderResponse>('/api/v2/payments/affirm/charge', body, {
      params: { pt },
    })
    .then((response) => {
      const orderResponse = { data: response.data }

      const { orderId, products } = orderResponse.data
      const amount = products?.totalPrice || 0
      const packageName = products?.planPrices[0]?.planName || 'Unknown Package'

      triggerVWOTracking(!!orderId, amount, packageName)

      return orderResponse
    })
    .catch((error) => {
      const packageName = body.planCodes?.[0] || 'Unknown Package'
      triggerVWOTracking(false, 0, packageName)
      throw error
    })
}

export const createBraintreeClientToken = () => {
  return axios.post<types.BraintreeClientTokenResponse>(
    '/api/v2/payments/braintree/clienttoken'
  )
}

export const createBraintreeCharge = (
  body: types.CreateBraintreeChargePayload,
  pt?: string
) => {
  return axios
    .post<types.OrderResponse>('/api/v2/payments/braintree/charge', body, {
      params: { pt },
    })
    .then((response) => {
      const orderResponse = { data: response.data }

      const { orderId, products } = orderResponse.data
      const amount = products?.totalPrice || 0
      const packageName = products?.planPrices[0]?.planName || 'Unknown Package'

      triggerVWOTracking(!!orderId, amount, packageName)

      return orderResponse
    })
    .catch((error) => {
      const packageName = body.planCodes?.[0] || 'Unknown Package'
      triggerVWOTracking(false, 0, packageName)
      throw error
    })
}

export const calculateProductPrices = (body: types.CalculatePricesPayload) => {
  return axios.post<types.CalculatePricesResponse>(
    '/api/v2/payments/prices/calculate',
    body
  )
}
